const Support = ({ ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 8C6 5.31243 8.38557 3 11.5 3C14.6144 3 17 5.31243 17 8V15.9998V16C17 18.6876 14.6144 21 11.5 21C10.9477 21 10.5 21.4477 10.5 22C10.5 22.5523 10.9477 23 11.5 23C15.2488 23 18.4614 20.3977 18.9389 16.9031C21.3401 16.399 23 13.9949 23 11.3546C23 9.01634 21.7283 6.91878 19.7918 6.08183C19.4359 5.92802 19.0398 5.99718 18.7595 6.22744C17.9122 3.17928 14.9306 1 11.5 1C8.06938 1 5.08778 3.17927 4.24046 6.22744C3.96017 5.99718 3.56405 5.92802 3.20817 6.08183C1.2717 6.91878 0 9.01634 0 11.3546C0 14.3183 2.09153 16.9846 4.97533 16.9997C4.98353 16.9999 4.99175 17 5 17C5.55228 17 6 16.5523 6 16V15.9998V8ZM4 14.7851V8C4 7.97269 4.00016 7.94542 4.00049 7.9182C2.89499 8.39663 2 9.70214 2 11.3546C2 13.0298 2.89013 14.3001 4 14.7851ZM18.9995 7.9182C18.9998 7.94542 19 7.97269 19 8V14.7851C20.1099 14.3001 21 13.0298 21 11.3546C21 9.70214 20.105 8.39663 18.9995 7.9182Z"
        fill="currentColor"
      ></path>
    </svg>
  )
}

export default Support
